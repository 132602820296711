import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson7/step47-1.png'
import img2 from '../../../image/lessons/lesson7/step47-2.png'
import img3 from '../../../image/lessons/lesson7/step47-3.png'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step47 extends React.Component{

    state = {
        activeBlock: 0,
        blocks: []
    }

    showInfo = (activeBlock) => {
        const {blocks} = this.state;
        if (blocks.indexOf(activeBlock) === -1) {
            blocks.push(activeBlock)
        }

        this.setState({
            activeBlock: activeBlock,
            blocks: blocks
        })

        if (blocks.length === 3) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {blocks} = this.state;
        return (
            <div className="step47">
                <TextBlock
                    text={`Единственной допустимой альтернативой такой калечащей операции, как онихэктомия («мягкие лапки»), может служить только стрижка когтей. В&nbsp;крайнем случае можно использовать специальные защитные колпачки для когтей, если кошка не&nbsp;испытывает стресс, приучена к&nbsp;ним с&nbsp;детства и&nbsp;это не&nbsp;ограничивает ее&nbsp;возможность пользоваться когтеточкой.`}
                />
                <InfoBlock
                    text={`Нажмите на картинки, чтобы узнать больше о стрижке когтей.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img
                            src={img1}
                            alt=""
                            className="flex_el_img"
                            onClick={() => {this.showInfo(1)}}
                        />
                        <div className={'flex_el_box ' + (blocks.indexOf(1) >= 0 && 'active')}>
                            <ul className="list">
                                <li className="list_item">
                                    Подстригать кончики ногтей необходимо примерно раз в неделю.
                                </li>
                                <li className="list_item">
                                    Существует специально разработанные для кошачих когтей триммеры/когтерезы.
                                </li>
                                <li className="list_item">
                                    Чтобы процедура стрижки когтей не превратилась в кошмар для животного и владельца, кошку нужно приучить не проявлять признаков тревоги, когда прикасаются к ее лапам или зажимают их в руках.
                                </li>
                                <li className="list_item">
                                    Для начала необходимо просто погладить ее лапы, постепенно увеличивая давление, чтобы поглаживание переросло в мягкое надавливание на лапу. На этом этапе можно поощрить кошку лакомством, пока она не смирится, что ее держат за лапку.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img
                            src={img2}
                            alt=""
                            className="flex_el_img"
                            onClick={() => {this.showInfo(2)}}
                        />
                        <div className={'flex_el_box ' + (blocks.indexOf(2) >= 0 && 'active')}>
                            <ul className="list">
                                <li className="list_item">
                                    Когда она будет готова, можно слегка надавить на лапу кошки большим пальцем сверху, а указательным снизу, пока коготь не вытянется.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img
                            src={img3}
                            alt=""
                            className="flex_el_img"
                            onClick={() => {this.showInfo(3)}}
                        />
                        <div className={'flex_el_box ' + (blocks.indexOf(3) >= 0 && 'active')}>
                            <ul className="list">
                                <li className="list_item">
                                    Рядом с когтевым ложем виден чувствительный участок - розовая область, которая представляет собой небольшой кровеносный сосуд.
                                </li>
                                <li className="list_item">
                                    Ни в коем случае нельзя стричь розовую часть когтя, поскольку она будет кровоточить, а кошке будет очень больно.
                                </li>
                                <li className="list_item">
                                    Нужно отрезать только острый кончик когтя, чтобы он притупился.
                                </li>
                                <li className="list_item">
                                    Для начала нужно подстригать когти только на одной лапе, на следующий день – на другой и т. д. Когда кошка достаточно привыкнет к стрижке когтей, можно обрезать когти сразу на всех четырех лапах за раз.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
